.bar {
  background: #f0f2f5;
  z-index: 9999;
  top: 0px;
  box-shadow: none;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  //margin-top: -50px;

  .bar-content {
    background: #f0f2f5;
  }

  h2 {
    margin-bottom: 0px !important;
  }
}
