.nucleus--block-row {
  margin-bottom: 10px;
  margin-left: 5px;
  width: 100%;
  margin-right: 5px;

  .card {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: none;
    background: #fafafa;

    .empty-card {
      opacity: 0.8;
    }

    .field-row {
      display: flex;
      width: 100%;

      .ant-form-item {
        width: 100%;
      }
    }
  }
}
