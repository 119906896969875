$primary-color: #3f51b5;
$secondary-color: #2879ff;

$green: #43a047;
$text-color: rgba(0, 0, 0, 0.9);

$input-placeholder-color: hsv(0, 0, 35%);
$form-item-margin-bottom: 15px;
$zindex-dropdown: 9999;
$input-height-base: 35px;
$border-radius-base: 3px;
$border-radius-sm: 2px;
$outline-width: 0px;
$zindex-modal: 9001;
$zindex-modal-mask: 9000;
$card-radius: 4px;
$border-width-base: 1px;
$font-size-base: 14px;
$typography-title-font-weight: 500;

// vertical paddings
$padding-lg: 21px; // containers
$padding-md: 13px; // small containers and buttons
$padding-sm: 10px; // Form controls and items
$padding-xs: 6px; // small items

$table-padding-vertical: 10px;
