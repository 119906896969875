@import 'variables.scss';

.ant-card {
  margin-bottom: 15px !important;
}

.ant-select {
  width: 100%;
  max-width: 400px;
}

.ant-drawer {
  z-index: 1000 !important;
}

.ant-form-item-label {
  line-height: 26px !important;
  color: rgba(0, 0, 0, 0.7);
  font-size: 0.9em;
  font-weight: 500;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-form-extra {
  font-size: 0.9em !important;
  font-weight: 500;
}

.ant-form-explain {
  font-size: 0.85em;
  font-weight: 500;
}

.ant-tabs {
  overflow: visible !important;
}

.ant-tabs-nav-wrap {
  padding-left: 2px;
}

.ant-input-search {
  input {
    &::placeholder {
      font-weight: 500 !important;
      font-size: 0.9em;
      color: black;
    }
  }
}

.ant-descriptions-item-label {
  font-weight: 500;
}

.ant-tag {
  font-weight: 500;
}
.ant-tag-green {
  color: #43a047;
}

.ant-table-body {
  margin: 0px !important;
}

.ant-table-small {
  th {
    background-color: #fafafa !important;
  }
}

.ant-select-selection__choice {
  font-weight: 500 !important;
}
